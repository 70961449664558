<template>
  <div>
    <section>
      <template>
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <!-- <Subheader icon="mdi-calendar" title="Allgemein"></Subheader> -->
              <div class="mt-3">
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <event-details-card
                      :loading="helpers.dataIsLoading"
                      :event="data"
                    ></event-details-card>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-skeleton-loader
                      v-if="helpers.dataIsLoading"
                      elevation="2"
                      type="card-heading, list-item, divider, article, divider, actions"
                    ></v-skeleton-loader>
                    <v-alert
                      v-if="
                        userResponse.isCanceled ||
                        cancellationNotice.status !== 'empty' ||
                        helpers.createdCancellationNotice
                      "
                      type="error"
                      elevation="2"
                      colored-border
                      border="left"
                    >
                      <div>
                        Deine Teilnahme von der Veranstaltung ist abgesagt.
                      </div>
                    </v-alert>

                    <v-alert
                      v-else-if="
                        responseIsExisting &&
                        schedulingConfig.cancellation.allowNotice &&
                        !helpers.dataIsLoading &&
                        (deadlineHasExpired ||
                          (data.config.responses.areBinding &&
                            (userResponse.status.id === '1' ||
                              userResponse.status.id === '3'))) &&
                        !eventHasStarted &&
                        (userResponse.status.id === '1' ||
                          userResponse.status.id === '3') &&
                        !userResponse.isCanceled &&
                        !helpers.createdCancellationNotice
                      "
                      type="error"
                      elevation="2"
                      colored-border
                      border="left"
                    >
                      <div>
                        Melde dich von der Teilnahme an der Veranstaltung aus
                        wichtigem Grund ab.
                      </div>
                      <v-btn
                        depressed
                        color="error"
                        class="mt-3"
                        @click="toggleResponseCancellationNoticeDialog()"
                        ><v-icon left>mdi-run-fast</v-icon>Teilnahme
                        absagen</v-btn
                      >
                    </v-alert>
                    <v-card
                      v-if="
                        !helpers.dataIsLoading &&
                        checkPermission(manageOwnResponsePermission)
                      "
                    >
                      <v-card-title
                        ><span>Meine Rückmeldung</span>
                        <v-spacer></v-spacer>
                        <v-chip
                          v-if="data.config.responses.areLocked"
                          color="purple lighten-4"
                          small
                          class="font-weight-medium purple--text text--darken-4"
                          ><v-icon small left>mdi-lock</v-icon>Rückmeldungen
                          gesperrt</v-chip
                        >
                      </v-card-title>
                      <v-card-subtitle>
                        Rückmeldefrist:
                        {{ getDeadline(data.config.responses.deadline, true) }}
                        Uhr
                        <br />
                        <v-chip
                          v-if="deadlineHasExpired"
                          small
                          class="mt-2 mr-2 font-weight-medium red--text text--darken-4"
                          color="red lighten-4"
                          ><v-icon small left>mdi-clock-remove</v-icon>Frist
                          abgelaufen</v-chip
                        >
                        <v-chip
                          v-if="data.config.attendance.isMandatory"
                          small
                          class="mt-2 mr-2 font-weight-medium red--text text--darken-4"
                          color="red lighten-4"
                          ><v-icon small left>mdi-alert-circle</v-icon
                          >Anwesenheitspflicht</v-chip
                        >
                        <v-chip
                          v-if="data.config.responses.areBinding"
                          small
                          class="mt-2 mr-2 font-weight-medium red--text text--darken-4"
                          color="red lighten-4"
                          ><v-icon small left>mdi-check-decagram</v-icon
                          >Rückmeldungen verbindlich</v-chip
                        >
                      </v-card-subtitle>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-alert
                          v-if="
                            userResponse.status.id !== '' &&
                            lastChangeWasByOtherUser
                          "
                          dense
                          type="warning"
                          text
                          class="mb-8"
                          >Deine Rückmeldung wurde
                          <span v-if="!lastChangeWasByOtherUser">zuletzt</span>
                          von
                          <span class="font-weight-medium">{{
                            userResponse.meta.lastUpdatedByDisplayName
                          }}</span>
                          am
                          {{
                            timestampConverter(userResponse.meta.lastUpdatedAt)
                          }}
                          Uhr bearbeitet.
                          <br /><br />
                          <span class="font-weight-medium">
                            Akzeptiere die Änderungen oder passe die Rückmeldung
                            an.</span
                          >
                        </v-alert>
                        <!-- MARK -->
                        <v-alert
                          v-if="checkIfSupport && checkIfItService"
                          dense
                          type="error"
                          outlined
                        >
                          Du verwendest ein IT-Service- oder Support-Konto und
                          kannst keine eigenen Rückmeldungen abgeben.
                        </v-alert>
                        <v-alert
                          v-else-if="
                            event.config.attendance.isLimited &&
                            !event.userIsInvited
                          "
                          dense
                          type="error"
                          outlined
                        >
                          Die Teilnahme an dieser Veranstaltung ist beschränkt.
                        </v-alert>
                        <v-form
                          v-else
                          v-model="helpers.formIsValid"
                          ref="form1"
                          @submit.prevent="updateResponse()"
                        >
                          <v-chip
                            v-if="
                              userResponse.type === 'automatedFromLeave' ||
                              userResponse.type === 'copiedFromRequest'
                            "
                            color="light-blue lighten-4"
                            label
                            small
                            class="font-weight-medium light-blue--text text--darken-4 mb-4 mr-2"
                            >{{
                              userResponse.type === "automatedFromLeave"
                                ? "Automatisierte Rückmeldung"
                                : "Übernommen aus Abfrage"
                            }}</v-chip
                          >

                          <v-chip
                            v-if="userResponse.isAccepted"
                            label
                            small
                            class="font-weight-medium mb-4 mr-2 orange--text text--darken-4"
                            color="orange lighten-4"
                            ><v-icon left small>mdi-star</v-icon
                            >Eingeteilt</v-chip
                          >
                          <v-chip
                            v-if="userResponse.assignedPosition.comboId"
                            label
                            small
                            class="font-weight-medium mb-4 mr-2"
                            ><v-icon left small>mdi-fire-truck</v-icon
                            >{{ userResponse.assignedPosition.unit.title }} –
                            {{
                              userResponse.assignedPosition.position.shortTitle
                            }}</v-chip
                          >
                          <v-chip
                            v-if="userResponse.assignedPosition.custom.text"
                            label
                            small
                            class="font-weight-medium mb-4"
                          >
                            {{
                              userResponse.assignedPosition.custom.text
                            }}</v-chip
                          >

                          <response-editor
                            v-model="userResponse"
                            :tasks="event.tasks"
                            :disableInput="
                              deadlineHasExpired ||
                              eventHasStarted ||
                              (responseIsExisting && responseIsBinding) ||
                              data.config.responses.areLocked
                            "
                          ></response-editor>
                        </v-form>
                      </v-card-text>
                      <v-divider
                        v-if="
                          (!checkIfSupport || !checkIfItService) &&
                          event.userIsInvited &&
                          !deadlineHasExpired &&
                          !eventHasStarted &&
                          !responseIsBinding
                        "
                      ></v-divider>

                      <v-card-actions
                        v-if="
                          (!checkIfSupport || !checkIfItService) &&
                          event.userIsInvited &&
                          !deadlineHasExpired &&
                          !eventHasStarted &&
                          (!responseIsBinding || !responseIsExisting) &&
                          !userResponse.meta.responseAvailable &&
                          !data.config.responses.areLocked
                        "
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          color="success"
                          :loading="helpers.isLoading"
                          @click="updateResponse()"
                        >
                          <v-icon left>{{
                            lastChangeWasByOtherUser
                              ? "mdi-check-circle"
                              : "mdi-content-save"
                          }}</v-icon>
                          {{
                            lastChangeWasByOtherUser
                              ? "Änderung akzeptieren"
                              : "Speichern"
                          }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code">
        <template v-slot:insideRow>
          <v-col cols="12" sm="6" md="6">
            <v-skeleton-loader
              v-if="helpers.dataIsLoading"
              elevation="2"
              type="card-heading, divider, list-item-avatar, list-item-avatar, list-item-avatar"
            ></v-skeleton-loader>
            <v-card v-else>
              <v-card-title> Metadaten </v-card-title>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-identifier</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>
                  <code>{{ data.meta.id }}</code>
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-timeline-clock-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>
                  Erstellt am
                  {{ timestampConverter(data.meta.createdAt) }} Uhr
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>
                  Erstellt durch
                  <code>{{ data.meta.createdBy }}</code>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-if="!data.meta.lastUpdated == undefined">
                <v-list-item-icon>
                  <v-icon>mdi-timeline-clock-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle
                  >Zuletzt aktualisiert am
                  {{ timestampConverter(data.meta.lastUpdatedAt) }}
                  Uhr</v-list-item-subtitle
                >
              </v-list-item>
              <v-list-item v-if="!data.meta.lastUpdated == undefined">
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle
                  >Zuletzt aktualisiert durch
                  <code>{{
                    data.meta.lastUpdatedBy
                  }}</code></v-list-item-subtitle
                >
              </v-list-item>
            </v-card>
          </v-col>
        </template>
      </support-tools>
    </section>
    <v-snackbar
      v-model="helpers.snackbar.isVisible"
      class="pb-4"
      bottom
      app
      timeout="3000"
      ><v-icon color="success" class="mr-1">mdi-check-circle</v-icon>
      {{ helpers.snackbar.message }}
    </v-snackbar>
    <CustomDialog
      v-if="helpers.responseCancellationNoticeDialog.model"
      @close="toggleResponseCancellationNoticeDialog()"
      title="Teilnahme absagen"
      text="Deine Rückmeldung wird automatisch in den Status 'Nicht verfügbar' gesetzt und ein Hinweis auf die Absage angezeigt. Sonstige Details werden in der Rückmeldeübersicht nicht angezeigt."
      width="m"
      fullscreenOnMobile
    >
      <template v-slot:content>
        <div class="mb-6">
          <v-chip
            v-if="userResponse.status.id == '1'"
            color="green lighten-4"
            class="green--text text--darken-4 font-weight-medium"
            ><v-icon left>mdi-check</v-icon
            ><span class="green--text text--darken-4 font-weight-medium"
              >Verfügbar</span
            ></v-chip
          >
          <v-chip
            v-else-if="userResponse.status.id == '3'"
            color="orange lighten-4"
            class="orange--text text--darken-4 font-weight-medium"
            ><v-icon left>mdi-check</v-icon>
            <span class="orange--text text--darken-4 font-weight-medium"
              >Wenn notwendig</span
            ></v-chip
          ><v-icon class="mx-2">mdi-arrow-right</v-icon>
          <v-chip
            color="red lighten-4"
            class="font-weight-medium red--text text--darken-4"
            >Kurzfristige Abmeldung</v-chip
          >
        </div>
        <v-form
          v-model="helpers.responseCancellationNoticeDialog.formIsValid"
          ref="cancellationNoticeForm"
          @submit.prevent="createResponseCancellationNotice()"
        >
          <response-cancellation-notice-editor
            v-model="cancellationNotice"
            mode="own"
          ></response-cancellation-notice-editor>
        </v-form>
        <notifications-forecast-preview-card
          infoText="Es wird versucht, Organisatoren der Veranstaltung und berechtigte
          Personen zu benachrichtigen."
          :notifyPersonsUids="
            schedulingConfig.cancellation.notifyPersons.concat(
              data.config.organizer.personIds
            )
          "
          notificationTriggerId="scheduling_eventCancellationNoticeCreated"
          class="my-2"
        ></notifications-forecast-preview-card>
      </template>
      <template v-slot:actions>
        <v-btn text @click="toggleResponseCancellationNoticeDialog()"
          >Abbrechen</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="error"
          :disabled="!helpers.responseCancellationNoticeDialog.formIsValid"
          @click="createResponseCancellationNotice()"
        >
          <v-icon left>mdi-run-fast</v-icon>Absage erstellen</v-btn
        ></template
      ></CustomDialog
    >
  </div>
</template>

<script>
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import EventDetailsCard from "@/components/scheduling/EventDetailsCard.vue";
import ResponseEditor from "@/components/scheduling/ResponseEditor.vue";
import ResponseCancellationNoticeEditor from "@/components/scheduling/ResponseCancellationNoticeEditor.vue";
import NotificationsForecastPreviewCard from "@/components/_systemwide/NotificationsForecastPreviewCard.vue";
import { db, auth } from "@/firebase";
import { SCHEDULING } from "@/store/modules.js";
import {
  UPDATE_EVENT_RESPONSE,
  CREATE_EVENT_CANCELLATION_NOTICE,
} from "@/store/action-types.js";
import { SCHEDULING_EVENTS_RESPONSES_OWN_update } from "@/data/permission-types.js";

export default {
  name: "scheduling-event-details-tab-general",
  components: {
    // Snackbar,
    CustomDialog,
    ResponseEditor,
    ResponseCancellationNoticeEditor,
    NotificationsForecastPreviewCard,
    SupportTools,
    EventDetailsCard,
  },
  props: {
    event: Object,
    eventType: Object,
  },
  data() {
    return {
      data: null,
      menu: [],
      manageOwnResponsePermission: `${SCHEDULING_EVENTS_RESPONSES_OWN_update}`,
      userResponseNotNull: false,
      userResponse: {
        status: {
          id: "",
        },
        isAccepted: false,
        type: "regular",
        assignedTasks: [],
        comment: "",
        assignedPosition: {
          custom: {
            text: "",
          },
          unit: {
            id: "",
            title: "",
          },
          position: {
            id: "",
            shortTitle: "",
          },
          comboId: "",
        },
        guests: {
          count: 0,
        },
        meta: {
          lastUpdatedBy: "",
          lastUpdatedByDisplayName: "",
        },
      },
      cancellationNotice: {
        notification: {
          message: "",
        },
        status: "empty",
      },
      helpers: {
        formIsValid: false,
        isLoading: false,
        dataIsLoading: true,
        createdCancellationNotice: false,
        responseCancellationNoticeDialog: {
          model: false,
          formIsValid: false,
        },
        snackbar: {
          isVisible: false,
          message: "",
        },
      },
    };
  },
  computed: {
    code() {
      return [
        { title: "event", data: this.event },
        { title: "eventType", data: this.eventType },
        { title: "schedulingConfig", data: this.schedulingConfig },
        { title: "userResponse", data: this.userResponse },
        { title: "cancellationNotice", data: this.cancellationNotice },
      ];
    },
    schedulingConfig() {
      return this.$store.state.organization.activeOrganization.config
        .scheduling;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    checkIfItService() {
      return this.$store.getters["organization/checkIfItService"];
    },
    responseIsExisting() {
      return this.userResponseNotNull && this.userResponse.status.id !== "";
    },
    responseIsBinding() {
      var eventResponsesAreBinding = this.data.config.responses.areBinding;
      if (eventResponsesAreBinding && this.userResponse.status.id !== "") {
        return true;
      } else {
        return false;
      }
    },
    lastChangeWasByOtherUser() {
      var lastUpdatedBy = this.userResponse.meta.lastUpdatedBy;
      if (
        lastUpdatedBy == null ||
        lastUpdatedBy == "" ||
        lastUpdatedBy === auth.currentUser.uid
      ) {
        return false;
      } else {
        return true;
      }
    },
    deadlineHasExpired() {
      if (this.data) {
        var deadline = this.getDeadline(
          this.data.config.responses.deadline,
          false
        );
        var now = new Date();

        if (now.getTime() > deadline.getTime()) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    eventHasStarted() {
      if (new Date().getTime() > this.data.duration.start.timestampToDate) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    // if (this.event !== null) {
    //   this.helpers.dataIsLoading = false;
    // }
    if (this.$route.query.changeStatus == true) {
      this.helpers.modals.changeStatus = true;
    }
    this.initialize();
  },
  watch: {
    $route: "initialize",
    event: function (newValue) {
      this.data = newValue;
    },
  },
  methods: {
    initialize() {
      this.data = this.event;
      if (this.$route.query.changeStatus == true) {
        this.helpers.modals.changeStatus = true;
      }

      // if (!this.checkIfSupport && !this.checkIfItService) {
      return this.getEventResponse()
        .then(() => {
          if (this.userResponse.isCanceled) {
            return this.getCancellationNotice();
          }
        })
        .then(() => {
          this.helpers.dataIsLoading = false;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    async getEventResponse() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("events")
        .doc(this.$route.params.itemId)
        .collection("responses")
        .doc(auth.currentUser.uid)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            this.userResponse = doc.data();
            this.userResponseNotNull = true;
            if (this.lastChangeWasByOtherUser) {
              this.userResponse.meta.lastUpdatedByDisplayName =
                await this.getUserDisplayName(
                  this.userResponse.meta.lastUpdatedBy
                );
            }
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    getCancellationNotice() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("events")
        .doc(this.$route.params.itemId)
        .collection("responses")
        .doc(auth.currentUser.uid)
        .collection("cancellations")
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.cancellationNotice = doc.data();
          } else {
            // doc.data() will be undefined in this case
            // console.log("No such document (no rm yet)!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    toggleResponseCancellationNoticeDialog() {
      this.helpers.responseCancellationNoticeDialog.model =
        !this.helpers.responseCancellationNoticeDialog.model;
    },
    getUserDisplayName(uid) {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("membershipsPublic")
        .doc(uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            return doc.data().user.displayName || "Unbekannt";
          } else {
            console.error("ERROR:[No user with this uid.]");
            return "Unbekannt";
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return "Unbekannt";
        });
    },
    getDeadline(deadline, withFormat) {
      if (deadline.isCustom) {
        // custom deadline
        if (withFormat) {
          return this.timestampConverter(deadline.timestamp);
        } else {
          return deadline.timestamp.toDate();
        }
      } else {
        // global deadline

        var durationInMin = this.schedulingConfig.defaultDeadlineInMinutes;
        //var durationInMin = 60;
        var MS_PER_MINUTE = 60000;
        var enddate = this.data.duration.start.timestampToDate;
        var calc = new Date(enddate - durationInMin * MS_PER_MINUTE);

        // V2: also working
        // var startdate = new Date(enddate);
        // const output = new Date(startdate.setUTCMinutes(enddate.getUTCMinutes() - durationInMin));

        if (withFormat) {
          // return this.timestampConverter(calc);
          const options = {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          };
          return calc.toLocaleDateString("de-DE", options);
        } else {
          return calc;
        }
      }
    },
    checkPermission(item) {
      if (item == "" || item == undefined) {
        return true;
      } else {
        return this.$store.getters["organization/checkPermissionByID"](item);
      }
    },
    userResponseIsNegative(responseStatusId) {
      var negativeValues = ["2", "3", "4"];
      return negativeValues.includes(responseStatusId);
    },
    updateResponse() {
      if (this.checkIfSupport && this.checkIfItService) {
        alert(
          "Fehler: Du verwendest ein IT-Service- oder Support-Konto und kannst keine eigenen Rückmeldungen abgeben."
        );
      } else if (!this.checkPermission(this.manageOwnResponsePermission)) {
        alert("Fehler: Du bist nicht berechtigt, diese Aktion auszuführen.");
      } else if (
        this.event.config.attendance.isLimited &&
        !this.event.userIsInvited
      ) {
        alert("Fehler: Die Teilnahme an dieser Veranstaltung ist beschränkt.");
      } else if (this.deadlineHasExpired) {
        alert(
          "Fehler: Die Rückmeldefrist ist bereits abgelaufen. Eine Rückmeldung kann nicht mehr abgegeben werden."
        );
      } else if (
        this.helpers.formIsValid &&
        this.responseIsBinding &&
        !this.userResponse.meta.responseAvailable
      ) {
        const res = confirm(
          "Abgegebene Rückmeldungen sind verbindlich. Eine abgegebene Rückmeldung kann anschließend nicht mehr geändert werden.\n\nRückmeldung speichern?"
        );
        if (res == true) {
          this.updateEventResponse();
          this.userResponse.meta.responseAvailable = true;
        }
      } else if (
        this.helpers.formIsValid &&
        this.event.config.attendance.isMandatory &&
        this.userResponseIsNegative(this.userResponse.status.id)
      ) {
        var res = confirm(
          "Für diese Veranstaltung besteht Anwesenheitspflicht!\nBist Du sicher, dass Du deine Rückmeldung absenden möchtest?"
        );
        if (res == true) {
          this.updateEventResponse();
        }
      } else if (
        this.userResponse.isAccepted &&
        (this.userResponse.status.id === "2" ||
          this.userResponse.status.id === "4")
      ) {
        const res = confirm(
          "Du bist für diese Veranstaltung bereits eingeteilt!\nBist Du sicher, dass Du deine Rückmeldung absenden möchtest? Deine Einteilung wird damit aufgehoben."
        );
        if (res == true) {
          return this.updateEventResponse();
        }
      } else if (this.helpers.formIsValid) {
        this.updateEventResponse();
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    createResponseCancellationNotice() {
      this.$refs.cancellationNoticeForm.validate();
      if (this.helpers.responseCancellationNoticeDialog.formIsValid) {
        this.helpers.isLoading = true;
        this.$store
          .dispatch(`${SCHEDULING}${CREATE_EVENT_CANCELLATION_NOTICE}`, {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,

            user: {
              displayName: auth.currentUser.displayName,
              uid: auth.currentUser.uid,
            },
            notification: {
              message: this.cancellationNotice.notification.message,
            },
          })
          .then(() => {
            this.helpers.createdCancellationNotice = true;
            return this.getCancellationNotice();
          })
          .then(() => {
            this.toggleResponseCancellationNoticeDialog();
            this.helpers.isLoading = false;
            this.helpers.snackbar.message =
              "Teilnahme abgesagt und Abmeldung erstellt. Es kann bis zu einer Minute dauern, bis die Verarbeitung der Abmeldung abgeschlossen ist.";
            this.helpers.snackbar.isVisible = true;
          })
          .catch((error) => {
            this.helpers.snackbar.message = error;
            this.helpers.snackbar.isVisible = true;
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    updateEventResponse() {
      this.isLoading = true;
      const response = this.userResponse;
      const hasConflictingStatus =
        response.status.id === "2" || response.status.id === "4";
      return this.$store
        .dispatch(`${SCHEDULING}${UPDATE_EVENT_RESPONSE}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
          uid: auth.currentUser.uid,
          status: {
            id: response.status.id,
          },
          assignedTasks: response.assignedTasks,
          isAccepted:
            response.isAccepted && hasConflictingStatus
              ? false
              : response.isAccepted,
          isCanceled: false,
          assignedPosition: {
            custom: {
              text: "",
            },
            unit: {
              id:
                response.isAccepted && hasConflictingStatus
                  ? ""
                  : response.assignedPosition.unit.id,
              title:
                response.isAccepted && hasConflictingStatus
                  ? ""
                  : response.assignedPosition.unit.title,
            },
            position: {
              id:
                response.isAccepted && hasConflictingStatus
                  ? ""
                  : response.assignedPosition.position.id,
              shortTitle:
                response.isAccepted && hasConflictingStatus
                  ? ""
                  : response.assignedPosition.position.shortTitle,
            },
            comboId:
              response.isAccepted && hasConflictingStatus
                ? ""
                : response.assignedPosition.comboId,
          },
          guests: {
            count: response.guests.count,
          },
          comment: response.comment,
        })
        .then(() => {
          response.meta.lastUpdatedBy = auth.currentUser.uid;
          if (response.isAccepted && hasConflictingStatus) {
            response.isAccepted = false;
            response.assignedPosition.unit.id = "";
            response.assignedPosition.unit.title = "";
            response.assignedPosition.position.id = "";
            response.assignedPosition.position.shortTitle = "";
            response.assignedPosition.comboId = "";
            response.meta.lastAssignedBy = null;
            response.meta.lastAssigedAt = null;
          }
          this.userResponseNotNull = true;
          this.helpers.isLoading = false;
          this.helpers.snackbar.message = "Rückmeldung gespeichert.";
          this.helpers.snackbar.isVisible = true;
        })
        .catch((error) => {
          this.helpers.snackbar.message = error;
          this.helpers.snackbar.isVisible = true;
        });
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };

      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>

<style></style>
