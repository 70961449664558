<template>
  <div>
    <NavigationBar
      titel="Rückmeldungen verwalten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-container>
        <!-- <v-row v-if="event.units.length > 0" class="match-height">
          <v-col cols="12" md="6">
            <v-card>
              <v-card-subtitle>Ausgewählte Einsatzmittel</v-card-subtitle>
              <v-card-text>
                <v-row dense class="match-height">
                  <v-col v-for="unit in eventUnits" :key="unit.id" cols="12">
                    <v-card outlined>
                      <v-img
                        :src="unit.photoUrl.size200x200"
                        contain
                        height="80px"
                        class="mx-4"
                      ></v-img>
                      <div class="px-4">
                        <span class="subtitle-1 font-weight-medium">{{
                          unit.title
                        }}</span>
                      </div>

                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Typ</th>
                              <th class="text-left">Besetzbarkeit</th>
                              <th class="text-left">Einteilung</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>LHF</td>
                              <td>
                                <v-chip
                                  label
                                  small
                                  color="green lighten-4"
                                  class="green--text text--darken-4 font-weight-medium"
                                  ><v-icon small left>mdi-check-circle</v-icon
                                  >Besetzbar</v-chip
                                >
                              </td>
                              <td>Einteilung offen</td>
                            </tr>
                            <tr>
                              <td>LHF-M</td>
                              <td>
                                <v-chip
                                  label
                                  small
                                  color="green lighten-4"
                                  class="green--text text--darken-4 font-weight-medium"
                                  ><v-icon small left>mdi-check-circle</v-icon
                                  >Besetzbar</v-chip
                                >
                              </td>
                              <td>Einteilung offen</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card>
              <v-card-subtitle>Bereits eingeteilte Funktionen</v-card-subtitle>
              <v-card-text>
                <v-row dense>
                  <v-col
                    v-for="position in positions"
                    :key="position.id"
                    cols="3"
                  >
                    <v-card
                      :class="[
                        'pa-2 text-h5 font-weight-bold text--darken-4 green--text',
                      ]"
                      dark
                      outlined
                      color="green lighten-4"
                    >
                      <div class="text-center">
                        {{ position.count }}
                      </div>
                      <div class="font-weight-medium subtitle-2 text-center">
                        {{ position.callsign }}
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-subtitle>Noch verfügbare Funktionen</v-card-subtitle>
              <v-card-text>
                <v-row dense>
                  <v-col
                    v-for="position in positions"
                    :key="position.id"
                    cols="3"
                  >
                    <v-card
                      :class="[
                        'pa-2 text-h5 font-weight-bold text--darken-4 green--text',
                      ]"
                      dark
                      outlined
                      color="green lighten-4"
                    >
                      <div class="text-center">
                        {{ position.count }}
                      </div>
                      <div class="font-weight-medium subtitle-2 text-center">
                        {{ position.callsign }}
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-skeleton-loader
              v-if="helpers.dataIsLoading"
              elevation="2"
              type="table-heading, table-thead, table-tbody"
            ></v-skeleton-loader>
            <v-card v-else>
              <event-responses-data-table
                :eventResponses="eventResponsesMatchedWithStatusGroup"
                mode="manage"
                @open-response-editor="openResponseEditor"
                @open-position-editor="openPositionEditor"
                @accept-response="acceptResponse"
              ></event-responses-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
    <section>
      <v-navigation-drawer
        v-model="helpers.positionDialog.model"
        right
        app
        :disable-resize-watcher="false"
        :disable-route-watcher="false"
        stateless
        touchless
        clipped
        width="420px"
        height="100vh"
      >
        <v-toolbar
          fixed
          elevation="0"
          :style="{
            'border-bottom-width': 'thin !important',
            'border-color': $vuetify.theme.dark
              ? 'rgb(255,255,255,0.12)'
              : 'rgb(0,0,0,0.12)',
            'border-bottom-style': 'solid !important',
          }"
          ><v-toolbar-title>Person einteilen</v-toolbar-title
          ><v-spacer></v-spacer>
          <v-btn icon @click="togglePositionDialog(false)"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-toolbar
        >
        <div class="mx-3 my-5">
          <response-position-editor
            v-model="selectedUserResponse.assignedPosition"
          ></response-position-editor>
        </div>
      </v-navigation-drawer>
    </section>
    <section>
      <custom-dialog
        v-if="helpers.responseDialog.model"
        @close="toggleResponseDialog(false)"
        title="Rückmeldung bearbeiten"
        width="s"
        fullscreenOnMobile
        :btnAction="saveResponse"
        btnText="Speichern"
        btnColor="success"
        :errorMessage="helpers.responseDialog.errorMessage"
        :btnDisabled="!helpers.responseDialog.formIsValid"
      >
        <template v-slot:content>
          <v-card outlined class="mb-4">
            <v-card-subtitle class="pl-2 font-weight-medium">
              {{ selectedUserResponse.user.displayName }}</v-card-subtitle
            >
          </v-card>
          <v-form
            v-model="helpers.responseDialog.formIsValid"
            ref="form"
            @submit.prevent="saveResponse"
          >
            <response-editor
              v-model="selectedUserResponse"
              :tasks="event.tasks"
            ></response-editor>
            <v-card outlined class="mt-4">
              <v-card-text class="py-0">
                <v-switch
                  v-model="selectedUserResponse.isAccepted"
                  :disabled="selectedUserResponse.status.id === '0'"
                  :rules="[rules.onlyWhenStatusIsCorrect]"
                  inset
                  dense
                  label="Person als eingeteilt markieren"
                  color="orange"
                  append-icon="mdi-star"
                ></v-switch>
              </v-card-text>
            </v-card>
            <v-text-field
              v-model.trim="selectedUserResponse.assignedPosition.custom.text"
              outlined
              clearable
              counter
              label="Einteilungsvermerk"
              :rules="[rules.onlyWhenStatusIsCorrect]"
              hint="Keine statistische Erfassung; dafür Funktionseinteilung verwenden"
              persistent-hint
              maxlength="18"
              placeholder="Bspw. Reserve / Springer "
              class="mt-4"
            ></v-text-field>
          </v-form>
        </template>
      </custom-dialog>
    </section>
    <section>
      <custom-dialog
        v-if="helpers.updateDialog.model"
        @close="toggleUpdateDialog()"
        title="Änderungen prüfen"
        text="Änderungen an fremden Rückmeldungen werden gekennzeichnet, solange sie nicht akzeptiert wurden. Zusätzlich können Personen über Änderungen an ihrer Rückmeldung oder über eine Einteilung informiert werden. Folgende Änderungen wurden erkannt:"
        width="l"
        fullscreenOnMobile
        :errorMessage="helpers.updateDialog.errorMessage"
        :btnAction="updateResponses"
        :loading="helpers.updateDialog.loading"
        btnText="Rückmeldungen aktualisieren"
        btnColor="success"
        :btnDisabled="!changedResponses.length"
      >
        <template v-slot:content>
          <v-card outlined class="mt-2">
            <v-data-table
              :items="changedResponses"
              :headers="helpers.updateDialog.change.headers"
              sort-by="user.displayName"
              item-key="meta.uid"
              disable-pagination
              hide-default-footer
              mobile-breakpoint="0"
              fixed-header
              height="35vh"
              class="mt-1"
              no-data-text="Es wurden keine Änderungen an Rückmeldungen vorgenommen."
            >
              <template v-slot:[`item.user`]="{ item }">
                <div class="d-flex flex-column">
                  <span
                    class="d-block font-weight-semibold text--primary text-truncate"
                    >{{ item.user.displayName }}</span
                  >
                  <small class="grey--text">{{ item.group.title }}</small>
                </div>
              </template>
              <template v-slot:[`item.comment`]="{ item }">
                <span
                  v-if="item.comment !== item.previousData.comment"
                  class="d-inline-block text-truncate"
                  style="max-width: 115px"
                  >{{ item.comment }}</span
                >
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  v-if="item.status.id !== item.previousData.status.id"
                  :color="`${statusColor(item.status.id)} lighten-4`"
                  label
                  small
                  :class="`font-weight-medium mr-2 ${statusColor(
                    item.status.id
                  )}--text text--darken-4`"
                >
                  <v-icon left small>{{ statusIcon(item.status.id) }}</v-icon>
                  {{ statusText(item.status.id) }}</v-chip
                >
              </template>
              <template v-slot:[`item.guests`]="{ item }">
                <span
                  v-if="item.guests.count !== item.previousData.guests.count"
                  >{{ item.guests.count }}</span
                >
              </template>
              <template v-slot:[`item.isAccepted`]="{ item }">
                <v-chip
                  v-if="item.isAccepted !== item.previousData.isAccepted"
                  :class="
                    item.isAccepted &&
                    item.isAccepted !== item.previousData.isAccepted
                      ? 'font-weight-medium mr-2 orange--text text--darken-4'
                      : 'font-weight-medium mr-2 red--text text--darken-4'
                  "
                  :color="
                    item.isAccepted &&
                    item.isAccepted !== item.previousData.isAccepted
                      ? 'orange lighten-4'
                      : 'red lighten-4'
                  "
                  label
                  small
                >
                  <v-icon left small>{{
                    item.isAccepted &&
                    item.isAccepted !== item.previousData.isAccepted
                      ? "mdi-star"
                      : "mdi-star-off"
                  }}</v-icon>
                  <span>{{
                    item.isAccepted &&
                    item.isAccepted !== item.previousData.isAccepted
                      ? "Eingeteilt"
                      : "Aufgehoben"
                  }}</span>
                  <span v-if="item.assignedPosition.comboId"
                    >: {{ item.assignedPosition.unit.shortTitle }}
                    {{ item.assignedPosition.position.shortTitle }}</span
                  >
                  <span v-if="item.assignedPosition.custom.text"
                    >: {{ item.assignedPosition.custom.text }}</span
                  >
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </template>
      </custom-dialog>
    </section>
  </div>
</template>

<script>
import { db, auth } from "@/firebase";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import ResponseEditor from "@/components/scheduling/ResponseEditor.vue";
import ResponsePositionEditor from "@/components/scheduling/ResponsePositionEditor.vue";
import EventResponsesDataTable from "@/components/scheduling/EventResponsesDataTable.vue";
import { ADMIN, AVAILABILITY, SCHEDULING } from "@/store/modules.js";
import {
  GET_UNITS,
  GET_USER_LIST,
  UPDATE_EVENT_RESPONSES,
} from "@/store/action-types.js";

export default {
  name: "scheduling-event-details-manage-responses",
  components: {
    NavigationBar,
    ResponseEditor,
    ResponsePositionEditor,
    EventResponsesDataTable,
    CustomDialog,
    SupportTools,
  },
  data() {
    return {
      rules: {
        onlyWhenStatusIsCorrect: () =>
          !(
            this.selectedUserResponse.isAccepted &&
            (this.selectedUserResponse.status.id === "0" ||
              this.selectedUserResponse.status.id === "2" ||
              this.selectedUserResponse.status.id === "4")
          ) || "Nicht bei diesem Rückmeldestatus möglich.",
      },
      eventResponses: [],
      // eventResponsesMatchedWithStatusGroup: [],
      positions: [
        {
          callsign: "FzF",
          count: "4",
        },
        {
          callsign: "Ma (+C)",
          count: "4",
        },
        {
          callsign: "TF",
          count: "4",
        },
        {
          callsign: "TL",
          count: "4",
        },
      ],
      helpers: {
        dataIsLoading: true,
        responseDialog: {
          model: false,
          formIsValid: false,
        },
        positionDialog: {
          model: false,
        },
        updateDialog: {
          isUpdatingResponses: false,
          model: false,
          errorMessage: "",
          loading: false,
          change: {
            headers: [
              { text: "Name", value: "user", divider: true },
              { text: "Status", value: "status", divider: true },
              { text: "Kommentar", value: "comment", divider: true },
              { text: "Gäste", value: "guests", divider: true },
              { text: "Eingeteilt", value: "isAccepted", divider: true },
            ],
          },
        },
      },
      selectedUserResponse: this.createBaseResponse(),
      selectedUserResponse2: this.createBaseResponse(),

      event: null,
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.toggleUpdateDialog,
        },
      ],
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.helpers.updateDialog.isUpdatingResponses) {
      next();
    } else {
      const answer = window.confirm(
        "Willst Du die Seite wirklich verlassen? Nicht gespeicherte Änderungen gehen verloren."
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    }
  },
  watch: {
    "selectedUserResponse.status.id": function () {
      if (this.helpers.responseDialog.model) {
        this.$nextTick(() => {
          this.validate();
        });
      }
    },
  },
  computed: {
    code() {
      return [
        { title: "event", data: this.event },
        { title: "eventResponses", data: this.eventResponses },
        { title: "membershipsPrepared", data: this.membershipsPrepared },
        {
          title: "eventResponsesMatchedWithStatusGroup",
          data: this.eventResponsesMatchedWithStatusGroup,
        },
        {
          title: "membershipsPreparedDisplayName",
          data: this.membershipsPrepared.map((a) => a.user.displayName),
        },
      ];
    },
    units() {
      return this.$store.state.availability.units;
    },
    eventUnits() {
      return this.units.filter((unit) =>
        this.event.units.some((eventUnit) => eventUnit.id === unit.meta.id)
      );
    },
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
    membershipsPrepared() {
      if (!this.event) return [];
      const range = {
        start:
          new Date(this.event.duration.start.timestampToDate) || new Date(),
        end: new Date(this.event.duration.end.timestampToDate) || new Date(),
      };

      const membershipsFiltered = this.memberships.filter((obj) => {
        const memberSince = new Date(obj.user.memberSinceToDate);
        const memberUntil = new Date(obj.user.memberUntilToDate);

        return (
          memberSince <= range.start && // All members that where members before or at the start of this event
          !(memberUntil <= range.end) && // All members that didn't leave before or at the event of this event
          obj.access.type === "member"
        );
      });

      const result = membershipsFiltered.map((item) => {
        return {
          groupByGroup:
            item.group.sortKey.toString().padStart(4, "0") +
            "_" +
            item.group.title,
          groupByDivision:
            item.user.division.sortKey.toString().padStart(4, "0") +
            "_" +
            item.user.division.title,
          id: item.group.id,
          group: {
            title: item.group.title,
            sortKey: item.group.sortKey,
          },
          user: {
            displayName: item.user.displayName,
            uid: item.user.uid,
            division: {
              title: item.user.division.title,
              shortTitle: item.user.division.shortTitle,
              sortKey: item.user.division.sortKey,
              id: item.user.division.id,
            },
          },
        };
      });

      return result;
    },
    eventResponsesMatched() {
      const memberships = this.membershipsPrepared;
      if (!this.event || !memberships) return [];

      // 1: Match memberships to event status
      const matched = this.eventResponses.map((response) => {
        const matchedMembership = memberships.find(
          (membership) => membership.user.uid === response.meta.uid
        );
        return { ...response, ...matchedMembership };
      });

      // 2: Find missing memberships
      const missing = memberships.filter((o) =>
        matched.every((s) => s.user.uid !== o.user.uid)
      );

      // 3: Create event status for missing memberships
      const addedStatus = missing.map((obj) => {
        const baseResponse = this.createBaseResponse();
        return {
          ...obj,
          ...baseResponse,
          meta: {
            lastUpdatedBy: "",
            uid: obj.user.uid,
          },
          previousData: {
            ...baseResponse,
            meta: {
              lastUpdatedBy: "",
              uid: obj.user.uid,
            },
          },
        };
      });

      // 4: Combine both groups as output
      const output = matched.concat(addedStatus);

      // 5: Delete items with user.uid or group.id not in allowedAttendeesIds, excluding items with status.id !== "0"
      if (this.event.config.attendance.isLimited) {
        const allowedAttendeesIds = new Set(
          this.event.config.attendance.allowedAttendeesIds
        );
        const outputFiltered = output.filter((obj) => {
          return (
            allowedAttendeesIds.has(obj.user.uid) ||
            allowedAttendeesIds.has(obj.group.id) ||
            allowedAttendeesIds.has(obj.user.division.id) ||
            obj.status.id !== "0"
          );
        });

        return outputFiltered;
      }
      return output;
    },
    eventResponsesMatchedWithStatusGroup: {
      // getter
      get() {
        return this.eventResponsesMatched.map((response) => ({
          ...response,
          groupByStatus: `${this.orderPrefix(
            response.status.id
          )}_${this.statusText(response.status.id)}`,
        }));
      },
      // setter
      set(newValue) {
        console.log(newValue);
      },
    },
    changedResponses() {
      return this.eventResponsesMatchedWithStatusGroup.filter((response) => {
        const previousData = response.previousData;
        const currentData = response;

        return [
          previousData.status.id !== currentData.status.id,
          previousData.comment !== currentData.comment,
          previousData.guests.count !== currentData.guests.count,
          previousData.isAccepted !== currentData.isAccepted,
          previousData.assignedPosition.custom.text !==
            currentData.assignedPosition.custom.text,
          previousData.assignedPosition.unit.id !==
            currentData.assignedPosition.unit.id,
          previousData.assignedPosition.position.id !==
            currentData.assignedPosition.position.id,
          previousData.assignedPosition.comboId !==
            currentData.assignedPosition.comboId,
          previousData.assignedTasks.length !==
            currentData.assignedTasks.length,
        ].some(Boolean);
      });
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        await this.getEvent();
        await this.$store.dispatch(`${AVAILABILITY}${GET_UNITS}`, {
          organizationId: this.$route.params.organizationId,
        });
        await this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
          organizationId: this.$route.params.organizationId,
          queryIsFiltered: true,
          query: {
            type: "active",
          },
        });

        await this.getEventResponses();
        // this.eventResponsesMatchedWithStatusGroup =
        //   await this.calculateEventResponsesMatchedWithStatusGroup();
        this.helpers.dataIsLoading = false;
      } catch (error) {
        console.error("Error initializing:", error);
        throw new Error(`Initialization failed: ${error.message}`);
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    calculateEventResponsesMatchedWithStatusGroup() {
      return this.eventResponsesMatched.map((response) => ({
        ...response,
        groupByStatus: `${this.orderPrefix(
          response.status.id
        )}_${this.statusText(response.status.id)}`,
      }));
    },
    getEvent() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("events")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            this.event = data;
            this.event.duration.start.timestampToDate =
              data.duration.start.timestamp.toDate();
            this.event.duration.end.timestampToDate =
              data.duration.end.timestamp.toDate();
            this.event.userIsInvited = this.checkIfUserIsInvited(
              auth.currentUser.uid,
              this.$store.state.organization.membership.group.id,
              this.$store.state.organization.membership.user.division.id,
              data.config.attendance
            );
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          return false;
        });
    },
    getEventResponses() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("events")
        .doc(this.$route.params.itemId)
        .collection("responses")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            const response = {
              ...data,
              previousData: {
                ...data,
              },
            };
            if (
              response.meta.lastUpdatedBy &&
              response.meta.lastUpdatedBy !== response.meta.uid
            ) {
              response.meta.lastUpdatedByDisplayName = this.getDisplayName(
                response.meta.lastUpdatedBy
              );
            }
            this.eventResponses.push(response);
          });
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          return false;
        });
    },
    createBaseResponse() {
      return {
        comment: "",
        type: "regular",
        isCanceled: false,
        isAccepted: false,
        status: {
          id: "0",
        },
        assignedTasks: [],
        assignedPosition: {
          custom: {
            text: "",
          },
          unit: {
            id: "",
            title: "",
          },
          position: {
            id: "",
            shortTitle: "",
          },
          comboId: "",
        },
        guests: {
          count: 0,
        },
      };
    },
    openResponseEditor(response) {
      this.selectedUserResponse = JSON.parse(JSON.stringify(response));
      this.toggleResponseDialog(true);
    },
    openPositionEditor(response) {
      this.selectedUserResponse = response;
      this.togglePositionDialog(true);
    },
    saveResponse() {
      this.validate();
      if (this.helpers.responseDialog.formIsValid) {
        const responseIndex =
          this.eventResponsesMatchedWithStatusGroup.findIndex(
            (response) =>
              response.meta.uid === this.selectedUserResponse.meta.uid
          );
        Object.assign(
          this.eventResponsesMatchedWithStatusGroup[responseIndex],
          {
            ...this.selectedUserResponse,
            groupByStatus: `${this.orderPrefix(
              this.selectedUserResponse.status.id
            )}_${this.statusText(this.selectedUserResponse.status.id)}`,
          }
        );
        // this.$set(
        //   this.eventResponsesMatchedWithStatusGroup[responseIndex],
        //   "status.id",
        //   this.selectedUserResponse.status.id
        // );
        this.selectedUserResponse = this.createBaseResponse();
        this.toggleResponseDialog(false);
      } else {
        this.helpers.responseDialog.errorMessage =
          "Fehler: Bitte prüfe deine Eingabe.";
      }
    },
    toggleResponseDialog(value) {
      this.helpers.responseDialog.model = value;
      if (value) {
        this.$nextTick(() => {
          console.log(this.$refs);
          this.validate();
        });
      }
    },
    togglePositionDialog(value) {
      this.helpers.positionDialog.model = value;
    },
    toggleUpdateDialog() {
      this.helpers.updateDialog.errorMessage = "";
      this.helpers.updateDialog.model = !this.helpers.updateDialog.model;
    },
    acceptResponse(response) {
      console.log(response);
      this.selectedUserResponse2 = JSON.parse(JSON.stringify(response));
      const statusId = this.selectedUserResponse2.status.id;

      switch (statusId) {
        case "1":
        case "3":
          this.selectedUserResponse2.isAccepted =
            !this.selectedUserResponse2.isAccepted;
          break;
        case "2":
        case "4":
          this.selectedUserResponse2.isAccepted = false;
          break;
        case "0":
          this.selectedUserResponse2.status.id = "1";
          this.selectedUserResponse2.isAccepted = true;
          break;
      }

      this.saveResponse();
    },
    updateResponses() {
      this.helpers.updateDialog.isUpdatingResponses = true;
      this.helpers.updateDialog.loading = true;
      const editIsAllowed = true;
      if (editIsAllowed) {
        return this.$store
          .dispatch(`${SCHEDULING}${UPDATE_EVENT_RESPONSES}`, {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,

            responses: this.changedResponses,
          })
          .catch((error) => {
            console.log("Error updating responses:", error);
            this.helpers.updateDialog.errorMessage =
              "Fehler: Die Rückmeldungen konnten nicht aktualisiert werden.";
            this.helpers.updateDialog.loading = false;
          })
          .finally(() => {
            this.helpers.updateDialog.isUpdatingResponses = false;
          });
      } else {
        this.helpers.updateDialog.errorMessage =
          "Fehler: Rückmeldungen können nur vor Beginn der Veranstaltung geändert werden.";
        this.helpers.updateDialog.loading = false;
      }
    },
    getMemberships() {
      const isPlanned = this.data.status === "planned";
      this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: isPlanned,
        query: isPlanned
          ? { type: "active" }
          : {
              type: "archived",
              memberUntil: this.convertToDate(
                this.event.duration.start.timestamp
              ),
            },
      });
    },
    checkIfUserIsInvited(uid, userGroupId, userDivisionId, attendance) {
      if (attendance.isLimited) {
        return (
          attendance.allowedAttendeesIds.includes(uid) ||
          attendance.allowedAttendeesIds.includes(userGroupId) ||
          attendance.allowedAttendeesIds.includes(userDivisionId) ||
          attendance.allowedAttendeesIds.includes("all")
        );
      } else {
        return true;
      }
    },
    statusColor(item) {
      switch (item) {
        case "-0":
          return "purple";
        case "0":
          return "blue-grey";
        case "1":
          return "green";
        case "2":
          return "red";
        case "3":
          return "orange";
        case "4":
          return "blue";
        case "":
          return "blue-grey";
        default:
          return "";
      }
    },
    statusIcon(item) {
      switch (item) {
        case "-0":
          return "mdi-cancel";
        case "0":
          return "mdi-help";
        case "1":
          return "mdi-check";
        case "2":
          return "mdi-close";
        case "3":
          return "mdi-minus";
        case "4":
          return "mdi-airplane";
        case "":
          return "mdi-alert-outline";
        default:
          return "";
      }
    },
    statusText(item) {
      switch (item) {
        case "-1":
          return "Nicht eingeladen";
        case "0":
          return "Keine Rückmeldung";
        case "1":
          return "Verfügbar";
        case "2":
          return "Nicht verfügbar";
        case "3":
          return "Wenn notwendig";
        case "4":
          return "Nicht in Stadt";
        case "":
          return "Fehler";
        default:
          return "";
      }
    },
    orderPrefix(item) {
      switch (item) {
        case "-1":
          return "7";
        case "0":
          return "5";
        case "1":
          return "1";
        case "3":
          return "2";
        case "2":
          return "3";
        case "4":
          return "4";
        case "X":
          return "X";
        default:
          return "";
      }
    },
    getDisplayName(uid) {
      const user = this.memberships.find(
        (membership) => membership.user.uid === uid
      );
      return user.user.displayName;
    },
    getStatusCount(statusId) {
      if (statusId == "all") {
        return (
          this.countStatusById("1") +
          this.countStatusById("2") +
          this.countStatusById("3") +
          this.countStatusById("4")
        );
      } else {
        return this.countStatusById(statusId);
      }
    },
    countStatusById(statusId) {
      return this.eventResponsesMatched.reduce((n, userResponse) => {
        return n + (userResponse.status.id == statusId);
      }, 0);
    },
    convertToDate(date) {
      date = date.toDate();
      return (
        (date.getDate() < 10 ? "0" : "") +
        date.getDate() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        date.getFullYear()
      );
    },
  },
};
</script>
